<template>
    <div
        :class="{
            'c-badge--white': bkgColor === BADGE_COLORS.white,
            'c-badge--light-gray': bkgColor === BADGE_COLORS.lightGray,
            'c-badge--light-gray-translucent': bkgColor === BADGE_COLORS.lightGrayTranslucent,
            'c-badge--sunshine': bkgColor === BADGE_COLORS.sunshine,
            'c-badge--mini': isMiniBadge,
        }"
        class="c-badge o-text--label u-text--black"
    >
        <BaseIcon
            v-if="icon"
            :icon="icon"
            size="12px"
            :color="color"
            class="c-badge__icon"
        />
        <span v-if="text" class="c-badge__text">{{ text }}</span>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { BADGE_COLORS } from '~coreModules/core/js/badge-constants';
// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable vue/valid-define-props */
const props = defineProps({
    badgeText: {
        type: String,
        default: '',
    },
    bkgColor: {
        type: String,
        default: BADGE_COLORS.lightGrayTranslucent,
        validator: color => Object.values(BADGE_COLORS).includes(color),
    },
    // icon must be present in the map APP_ICONS (svg-constants.js)
    icon: {
        type: String,
        default: null,
    },
    color: {
        type: String,
        default: 'black',
        validator(color) {
            return [
                'primary',
                'secondary',
                'black',
                'gray',
                'medium-gray',
                'light-gray',
                'white',
            ].includes(color);
        },
    },
    borderRadius: {
        type: String,
        default: '8px',
        validator: radius => ['4px', '8px'].includes(radius),
    },
    isMiniBadge: {
        type: Boolean,
        default: false,
    },
});

const { t } = useI18n();

const text = computed(() => t(props.badgeText));

</script>

<style lang="scss">
    .c-badge {
        width: auto;
        height: $nu-spacer-4;
        display: inline-flex;
        align-items: center;
        padding: 0 $nu-spacer-2;
        border-radius: v-bind(borderRadius);

        &--mini {
            border-radius: 0 4px 0 0;
            height: $nu-spacer-3;
            padding: 0 6px;
        }

        &--white {
            background-color: $nu-white;
        }

        &--light-gray {
            background-color: $nu-gray--light;
        }

        &--light-gray-translucent {
            background-color: rgba($nu-gray--light, 0.8);
        }

        &--sunshine {
            background-color: $nu-sunshine-80;
        }

        &__icon {
            &:has(+ .c-badge__text) {
                margin-right: $nu-spacer-1;
            }
        }

        &__text {
            display: inline-flex;
        }
    }
</style>
